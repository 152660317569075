'use client'

import { theme } from '@ui/style/theme'
import { useEffect, useState } from 'react'

interface IconGoIconsProps extends SVGComponentProps {
	size: 'xl' | 'lg' | 'md' | 'sm'
	isDark?: boolean
	color?: string
}

const iconConfigs = {
	xl: {
		width: 36,
		height: 36,
		viewBox: '0 0 36 36',
		d: 'M23.5 8L33.5 18L23.5 28',
		strokeWidth: 3,
	},
	lg: {
		width: 36,
		height: 36,
		viewBox: '0 0 36 36',
		d: 'M25 10L33 18L25 26',
		strokeWidth: 2.5,
	},
	md: {
		width: 24,
		height: 24,
		viewBox: '0 0 24 24',
		d: 'M15 5L22 12L15 19',
		strokeWidth: 2,
	},
	sm: {
		width: 16,
		height: 16,
		viewBox: '0 0 16 16',
		d: 'M8.5 2.5L14 8L8.5 13.5',
		strokeWidth: 1.5,
	},
}

export const IconGoIcons = ({ size, isDark = false, color, className }: IconGoIconsProps) => {
	const colorValue = isDark ? theme.color.colWhite : color || theme.color.colBlack
	const [current, setCurrent] = useState({
		width: 36,
		height: 36,
		viewBox: '0 0 36 36',
		d: 'M25 10L33 18L25 26',
		strokeWidth: 2.5,
	})
	const { width, height, viewBox, d, strokeWidth } = current
	useEffect(() => {
		setCurrent(iconConfigs[size])
	}, [size])
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={viewBox}
			fill="none"
			className={className}
		>
			<path d={d} stroke={colorValue} strokeWidth={strokeWidth} />
		</svg>
	)
}
